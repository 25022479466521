define([], () => {

  const loyaltyPointsRewardsTable = () => {
    const component = {};

    const _config = {
      selectors: {
        accordionContainer: '[data-accordion-container]',
      },
      classNames: {
        accordionHeadClass: 'loyaltyPointsRewardsTable_accordionHeader',
        accordionShowClass: 'loyaltyPointsRewardsTable_accordion-show',
        accordionHideClass: 'loyaltyPointsRewardsTable_accordion-hide'
      }
    };

    const _init = element => {
      component.element = element;

      const accordionContainer = component.element.querySelector(component.config.selectors.accordionContainer);

      accordionContainer.addEventListener('click', component.accordionClickHandler);

      return component;
    };

    const _accordionClickHandler = (e) => {
      if (e.target.classList.contains(component.config.classNames.accordionHeadClass)) {
        const currentParent = e.target.parentElement;
        const accordionShow = component.element.querySelector(`.${component.config.classNames.accordionShowClass}`);

        if (currentParent.classList.contains(component.config.classNames.accordionShowClass)) {
          currentParent.classList.remove(component.config.classNames.accordionShowClass);
          currentParent.classList.add(component.config.classNames.accordionHideClass);
        }
        else {
          currentParent.classList.add(component.config.classNames.accordionShowClass);
          currentParent.classList.remove(component.config.classNames.accordionHideClass);
        }

        if (accordionShow && currentParent !== accordionShow) {
          accordionShow.classList.remove(component.config.classNames.accordionShowClass);
          accordionShow.classList.add(component.config.classNames.accordionHideClass);
        }
      }
    };

    component.config = _config;
    component.init = _init;
    component.accordionClickHandler = _accordionClickHandler;

    return component;
  };

  return loyaltyPointsRewardsTable;
});
